<template>
  <div class="container">
    <!-- 添加到桌面 -->
    <div v-if="type === 'add_desktop'">
      <div class="title">如何将小组件添加到桌面</div>
      <div class="second-title">1. 长按手机桌面空白处，或双指捏合（如下图示意），进入编辑状态。</div>
      <img
        class="intro-img"
        src="../../../assets/images/ky-widgets-tutorial/add_desktop_step1.jpeg"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">
        2. 点击「桌面组件/窗口小工具/添加插件/添加工具/桌面挂件...」，不同手机称呼可能不同。
      </div>
      <img
        class="intro-img"
        src="../../../assets/images/ky-widgets-tutorial/add_desktop_step2.jpeg"
        @click="handlePreviewImg($event)"
      />
      <div class="second-title">3. 找到快音App提供的小组件，点击或拖动至桌面即可使用。</div>
      <img
        class="intro-img"
        src="../../../assets/images/ky-widgets-tutorial/add_desktop_step3.jpeg"
        @click="handlePreviewImg($event)"
      />
    </div>
  </div>
</template>

<script>
  import Preview from '@/components/PreviewImg';

  export default {
    components: {},
    props: {
      type: {
        type: String,
      },
    },
    data() {
      return {
        previewInst: null,
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.previewInst = new Preview();
    },
    methods: {
      handlePreviewImg(e) {
        this.previewInst.show({ src: e.target.src });
      },
    },
  };
</script>

<style lang="less" scoped>
  .container {
    font-family: 'PingFang SC', tahoma, arial, 'helvetica neue', 'hiragino sans gb', 'microsoft yahei', sans-serif;
    padding: 0.2rem 0.4rem;
    img {
      margin: 0.3rem auto;
    }
    h1 {
      font-size: 0.28rem;
      margin-bottom: 0.3rem;
      color: #2c2c2c;
      font-weight: 500;
      line-height: 0.52rem;
    }
    .title {
      font-size: 0.42rem;
      color: #2c2c2c;
      line-height: 0.48rem;
      padding: 0.35rem 0;
      border-bottom: 1px solid #ced5df;
      margin-bottom: 0.3rem;
      font-weight: bold;
    }
    .second-title {
      font-size: 0.3rem;
      word-break: break-word;
      line-height: 0.52rem;
      color: #2c2c2c;
    }
    .intro-img {
      max-width: 100%;
      width: auto;
      height: auto;
      border-radius: 4px;
    }
  }
</style>
